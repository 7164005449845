import React, { useEffect, useRef } from "react"
import slugify from '@sindresorhus/slugify'
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, Autoplay } from "swiper"

import { Link } from "gatsby"
import Loading from "./Loading"

const ProductSlider = ({items}) => {
    const swiper = useRef()
    SwiperCore.use([Pagination, Autoplay])
    return(
      items.length > 1 ?
    <Swiper
          ref={swiper}
          slidesPerView={1}
          spaceBetween={10}
          rewind={true}
          observeSlideChildren={true}
          observer={true}
          pagination={{
            clickable: true,
          }}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          className="mySwiper p-16"
        >
          {items.map(item => (
            <SwiperSlide key={item.mysqlId}>
              <div className="py-8 px-2 max-w-sm mx-auto bg-white rounded-xl shadow-lg  sm:flex flex-col ">
                <div className="px-6 py-1 flex justify-center">
                  <img
                    className="mx-auto h-auto w-auto max-h-14 lg:h-20 sm:mx-0 sm:shrink-0 my-4"
                    src={`https://clicknpay.it/static-images/programs/${item.logo}`}
                    alt={item.name}
                  />
                </div>
                <hr className="mb-4" />
                <div className="text-center space-y-10">
                  <div className="space-y-0.5">
                    <p className="text-lg text-black font-semibold">
                      {item.name.replace(/_CPL/g, " ")}
                    </p>
                    <p className="text-sky-700 font-medium text-base ">
                      {item.products.display_name}
                    </p>
                  </div>

                  <Link
                    to={`/servizi/${slugify(item.name)}-${item.mysqlId}`}
                    className="px-2 py-1 mt-2 text-sm text-sky-900 font-medium inline-flex items-center justify-between rounded-md border border-sky-900  hover:bg-super hover:border-transparent hover:transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-super focus:ring-offset-2"
                  >
                    Scopri di più
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        :
        <Loading />
    )
}
export default ProductSlider;